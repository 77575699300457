<template>
  <div
    v-if="!unlock"
    class="d-flex flex-wrap gap align-center"
  >
    <v-tooltip
      bottom
      open-delay="200"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <pinned-vals-settings
            v-if="visibleVals && visibleVals.length"
            v-model="computedStateColumn"
            :document-type="targetSearch.documentType"
          />
        </div>
      </template>
      <span>{{$t('t.ValueFilterSettings')}}</span>
    </v-tooltip>
    <pinned-value
      v-for="item in visibleVals"
      :key="item[0].id"
      :val-item="item"
      :column-id="computedStateColumnId"
      :filter="targetSearch.filterSet"
    >
      <template v-slot:pins="{item}">
        <slot
          v-if="unlock"
          name="pins"
          :item="item"
        />
      </template>
    </pinned-value>
  </div>
  <div
    v-else
    class="d-flex flex-wrap gap align-center"
  >
    <draggable
      v-model="computedValsPinned"
      draggable=".draggable"
      @end="dataValsPinnedEmit"
      class="display-contents"
    >
      <pinned-filter
        data-cy="pinned-value"
        v-for="item in computedValsPinned"
        :key="item.id"
        :val-item="item"
        class="draggable"
      >
        <template v-slot:pins="{item}">
          <slot
            v-if="unlock"
            name="pins"
            :item="item"
          />
        </template>
      </pinned-filter>
    </draggable>
  </div>
</template>

<script>
import Search from '@/pages/search/controllers'

export default {
  components: {
    Draggable: () => import('vuedraggable'),
    PinnedValsSettings: () => import('./pinned-vals-settings'),
    PinnedFilter: () => import('./pinned-filter'),
    PinnedValue: () => import('./pinned-value')
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataStateColumnEmit: this.$nextTickDedup(this.emitStateColumn),
      dataValsPinnedEmit: this.$nextTickDedup(this.emitValsPinned),
      dataStateColumn: null,
      filterParamLoading: false,
      dataValsPinned: null
    }
  },
  computed: {
    computedStateColumn: {
      get () {
        return this.dataStateColumn
      },
      set (v) {
        this.dataStateColumn = v
        this.dataStateColumnEmit()
      }
    },
    computedStateColumnId () {
      return this.dataStateColumn?.col
    },
    computedValsPinned: {
      get () {
        return this.dataValsPinned
      },
      set (v) {
        this.dataValsPinned = v
        this.dataValsPinnedEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      this.targetSearch.setFilterSet(this.targetSearch.filterSet).execute()
    },
    emitStateColumn () {
      if (this.dataStateColumn !== this.stateColumn) {
        this.$emit('update:state-column', this.dataStateColumn)
      }
    },
    emitValsPinned () {
      if (!this.lodash.isEqual(this.valsPinned, this.dataValsPinned)) {
        this.$emit('update:vals-pinned', this.lodash.cloneDeep(this.dataValsPinned))
      }
    },
    async setSelectedValues (item, incExcSwitchValue) {
      await this.targetSearch.filterSet.updateSelectedValues(item, incExcSwitchValue, false)
      this.dataDocumentEmit()
    }
  },
  watch: {
    stateColumn: {
      immediate: true,
      handler (v) {
        this.dataStateColumn = this.stateColumn
        this.dataStateColumnEmit()
      }
    },
    valsPinned: {
      immediate: true,
      handler (v) {
        this.dataValsPinned = this.lodash.cloneDeep(v)
        this.dataValsPinnedEmit()
      }
    }
  },
  props: {
    unlock: Boolean,
    stateColumn: Object,
    targetSearch: Search,
    visibleVals: Array,
    valsPinned: Array
  }
}
</script>

<style lang="stylus" scoped>
.display-contents
  display contents

.draggable
  cursor move !important
</style>
